.c {
    /* height: 80vh; */
    position: relative;
    
  }
  
  .c-bg {
    width: 20px;
    height: 100%;
    background-color: #f98750;
    position: absolute;
  }
  
  .c-wrapper {
    padding: 50px;
    display: flex;
  }
  
  .c-left {
    flex: 1;
  }
  
  .c-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-right: 30px;
  }
  
  .c-title {
    font-size: 60px;
    width: 80%;
    margin-bottom: 30px;
  }
  
  .c-info-item {
    display: flex;
    align-items: center;
    margin: 50px 0px;
    font-weight: 300;
    width: 70%;
  }
  
  .c-icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
  
  .c-desc {
    font-weight: 200;
  }
  
  form {
    margin-top: 20px;
    
  }
  
  input {
    width: 50%;
    height: 50px;
    border: none;
    border-bottom: 1px solid black;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
  }
  
  textarea {
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
  }
  
  button {
    border: none;
    padding: 15px;
    background-color: #f98750;
    color: white;
    font-weight: 500;
    cursor: pointer;
  }

  /* sosmed is each social media icon's values or attributes */
.sosmed{        
     
  width: 25px;    
  margin: 5px;
  
  transition: 1s;
  
  
}

/*footers*/
footer {
  
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding-bottom: 50px;
    
     
}

@media screen and (max-width:750px) and (min-width:300px) {
  /* .c {
    height: 0vh;
    
    
  } */
  footer {
    
    margin-top: 100px;
    /* margin-bottom: 50px; */
  }
  
}
  
  @media screen and (max-width: 480px) {

    .c {
      height: 80vh;
      
    }

    .c-wrapper {
      flex-direction: column;
      padding: 0px 50px;
    }
  
    .c-title {
      font-size: 30px;
    }
  
    .c-info-item {
      margin: 20px 0px;
      width: 100%;
    }
  
    .c-desc {
      display: none;
    }
  
    form {
      margin-top: 0px;
      display: flex;
      flex-wrap: wrap;
    }
  
    input {
      width: 35%;
      height: 40px;
      margin: 10px;
      margin-left: 0;
    }
  
    button {
      margin-top: 10px;
    }

    
  }