/* To position main blog container in the center */
#blog-mainbody{
    display: flex;  
    justify-content: center;
    align-items: center;
    align-content: center;
    
}


.thumbnail-text:hover{
       
    transition: 0.3s;
    transform: scale(1.1, 1.1);
    
}


/* to adjust second container within the main container: purpose to adjust direction row/column */
.image-direction1{
    width: 1350px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-bottom: 50px;
    margin-left: 250px;
}

/* to adjust thumbnail/image size */
.image-size{
    width: 75px;
    height: 75px;
    object-fit: cover;
    /* border: 1px solid #ddd; */
    border-radius: 4px;
    padding: 5px;
    /* background-color: rgb(92, 92, 92); */
    
}

.pl-title {
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }



/* to adjust thumbnail and text of each flexbox elements */
.thumbnail-text{
    width: 18%;
    height: 24%;
    
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    
    margin: 15px;

}

.thumbnail-description{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
    
    padding-left: 10px;
   
    
}

/* To remove empty flexbox */
@media only screen and (max-width: 1360px) {
    .image-direction1{
        width: 600px;
        margin-top: 32px;
        margin-left: 150px;
        
    }
    .empty-box{
        display: none;
    }
    .thumbnail-description{
        padding-left: 0px;
        justify-content: center;
        align-items: center;
        align-content: center;
        
    }
    .thumbnail-text{
       
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    .pl-title {
        margin-top: 100px;
    }
}


/* @media only screen and (min-width: 1600px) {
    
    .image-direction1{
       
        margin-left: 250px;
    }
    
} */

@media only screen and (max-width: 900px) {
    .thumbnail-text:hover{
        
        transition: 0.3s;
        transform: scale(1.1, 1.1);
        
    }
    /* .thumbnail-description{
        padding-left: 0px;
        justify-content: center;
        align-items: center;
        align-content: center;
        
    }
    .thumbnail-text{
       
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    .pl-title {
        margin-top: 100px;
    }
     */
    
}



@media only screen and (min-width: 700px) and (min-height: 1300px) {
    
  
    .mainTitle {
        margin-top: -300px;
    }
}








/* Android phone potrait*/
@media only screen and (max-width: 876px) and (max-height: 1000px) {
    
  
    .mainTitle {
        margin-top: 625px;
    }
}

/* Android phone landscape */
@media only screen and (max-width: 1000px) and (max-height: 876px) {
    
  
    /* .mainTitle {
        margin-top: -1625px;
    } */
}






/* iPhone 12 */
@media screen and (max-width: 768px) {

    .mainTitle {
        margin-top: 675px;
    }

    
    
}



/* ipad potrait */
@media screen and (max-width: 769px) and (max-height: 1025px) {

    .mainTitle {
        margin-top: 0px;
    }
    
}

/* ipad landscape */
@media screen and (max-width: 1025px) and (max-height: 769px) {

    .mainTitle {
        margin-top: 0px;
    }
    .image-direction1{
        margin-bottom: -50px;
    }
    
    
}

/* iPhone 11 Pro */
@media screen and (max-width: 724px) {

    .mainTitle {
        margin-top: 675px;
    }

    
    
}

@media only screen and (max-width: 550px) {
    .thumbnail-text{      
        margin: 30px;    
    }

    .image-direction1{
        margin-left: 65px;
        margin-bottom: 50px;
    }

    
    
   
}

/* iphone XR potrait */
@media only screen and (max-width: 415px) and (max-height: 897px) {
    .image-direction1{
        margin-left: 75px;
        margin-bottom: 50px;
    }
    .mainTitle {
        margin-top: -80px;
    }
}

/* iphone 12 potrait */
@media only screen and (max-width: 391px) and (max-height: 845px) {
    .image-direction1{
        margin-left: 65px;
        margin-bottom: 50px;
    }
    .mainTitle {
        margin-top: 675px;
    }
}




/* iPhone 12 landscape*/
@media screen and (max-width: 845px) and  (max-height: 391px) {

    
    .mainTitle {
        margin-top: 675px;
    }

    .image-direction1{
       
        margin-bottom: 50px;
    }
    
}



