

.i {
    display: flex;
    height: 100vh;
    
  }
  
  .i-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .i-right {
    flex: 1;
    position: relative;
  }
  
  .i-left-wrapper {
    padding: 50px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
  }
  
  .i-intro {
    font-size: 30px;
    font-weight: 300;
  }
  
  .i-name {
    font-size: 60px;
  }
  
  .i-title {
    height: 50px;
    overflow: hidden;
  }
  
  .i-title-wrapper {
    height: 100%;
    animation: move 8s ease-in-out infinite alternate;
  }

  .i-desc{
    margin-top: 20px;
  }
  
  @keyframes move {
    34% {
      transform: translateY(-50px);
    }
    67% {
      transform: translateY(-100px);
    }
    100% {
      transform: translateY(-150px);
    }
    
  }
  
  .i-title-item {
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #f98750;
    display: flex;
    align-items: center;
    
  }
  
  .i-scroll {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20px;
  }
  
  .i-img {
    
    width: 90%;
    height: 90%;
    object-fit: cover;
    position: absolute;
    top: 10%;
  }
  
  .i-bg {
    clip-path: polygon(100% 0%, 100% 51%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    background-color: #f98750;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  @media screen and (max-width: 1365px) {

    .i-img {
    
      top: 12.5%;
      right: 0%;
    }

    .i-right {
      flex: 1;
      position: relative;
      
    }
    .i {
      margin-bottom: 200px;
      
    }
    
    
  }

 

  @media screen and (max-width: 800px) {
    
    .i-left-wrapper {
      padding: 40px;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      
      height: 100%;
    }
  
    .i-desc{
      margin-top: 0px;
      
    }

    .i-img {
      top: 12.5%;
    }
    .i {
      margin-bottom: 200px;
      
    }

    
    
  }
  
  /* iphone 12 size (768 width) and android(876 width) landscape */
  @media screen and (max-width: 876px) {

    .i {
    
      margin-bottom: 300px;
      
      display: flex;
      flex-wrap: wrap;
      
    }

    .i-img {
      top: 12.5%;
    }
    /* .i-intro {
      font-size: 30px;
      font-weight: 300;
    } */
    
  }

  @media screen and (min-width: 768px) and (max-height: 400px){

    

    .i-img {
      top: 10.5%;
    }
    
    
  }

  /* iPhone 11 Pro */
  @media screen and (max-width: 724px) {

    .i-title {
    
      display: none;
    }
    .i-img {
      top: 10%;
    }

    
    
  }
  
  @media screen and (max-width: 480px) {
    .i {
      flex-direction: column;
      margin-top: 0px;
      margin-bottom: -10px;
    }
   
  
    .i-left-wrapper {
    
      padding: 10px;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
    }
  
    .i-desc{
      display: none;
    }

    .i-title-item {
      
      justify-content: center;
    }
    
    .i-img {
    
      right:5%;
      top: 10%;
    }
    
  }