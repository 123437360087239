.p {
  
  
  height: 350px;
  margin: 20px 30px;
  border: 2px solid rgb(243, 242, 242);
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  /* background-color: red; */
  
  
  
}

.p-browser {
  height: 20px;
  background-color: rgb(243, 242, 242);
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
}

.p-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
  background-color: grey;
}

.p-img {
  width: 100%;
  transition: all 3s ease;
}

.pl-b {
  width: 500px;
  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 100px;
  
  
  align-items: center;
  align-content: center;
}

.pl-c {
  width: 500px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  
  
  
}

.p:hover .p-img {
  transform: translateY(-19%);
}


@media screen and (max-width: 1350px) {
  .p {
    
    width: 250px;
    height: 225px;
  }

  
}

@media screen and (max-width: 1235px) {
  .p {
    
    /* height: 40vh; */
    width: 250px;
    height: 225px;
  }

  
}

@media screen and (max-width: 480px) {
  .p {
    width: 60%;
    /* height: 25vh; */
    height: 203px;
  }
  .pl-b {
    width: 380px;
  }

  
}