.a {
    height: 100vh;
    display: flex;
    align-items: center;
    margin-top: 100px;
    
  }
  
  .a-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
  }
  
  .a-right {
    flex: 1;
    margin-right: 45px;
  }
  
  .a-card.bg {
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: #333;
  }
  
  .a-card {
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
  }
  
  .a-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .a-award-img {
    width: 120px;
    height: 120px;
    border-radius: 20px;
  }
  
  .a-title {
    font-weight: 400;
  }
  
  .a-sub {
    margin: 20px 0px;
  }
  
  .a-desc{
      font-weight: 300;
  }
  
  .a-award{
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  
  .a-award-texts{
      width: 70%;
  }
  
  .a-award-title{
      font-weight: bold;
      color: #555;
      margin-bottom: 10px;
  }


  @media screen and (max-width:1300px) and (min-width:900px){
    .a{
      margin-top: 450px;
      margin-bottom: 200px;
    }
    .a-card {
      height: 40vh;
    }

  }

  @media only screen and (min-width: 700px) and (min-height: 1300px) {
    
  
    .a{
      margin-top: -200px;
      margin-bottom: 200px;
    }
}


@media only screen and (min-width: 700px) and (max-height: 1300px) {
    
  .a{
    margin-top: 0px;
    margin-bottom: 0px;
  }
 
}
  
  @media screen and (max-width: 767px) and (min-height: 100px) {
    .a{
      /* margin-top: -75px; */
      margin-bottom: 500px;
    }
    

  }
  

  

  /* @media screen and (max-width:750px) and (max-height:400px){
    .a{
      
      margin-bottom: 500px;
    }

  } */


  


  @media screen and (max-width:876px) {
      .a{
        flex-direction: column;
        text-align: center;
        margin-top: 140px;
        margin-bottom: 500px;
      }
  
      .a-left {
        width: 100%;
      }
  
      /* .a-card {
        height: 40vh;
      } */
  
      .a-card.bg, .a-award {
        display: none;
      }
  
      .a-right{
        padding: 0px;
        margin: 20px
      }

      .a-img {
        margin-top: 0px;
        /* height: 40vh; */
        border-radius: 30px;

      }
  }

  
  

  @media screen and (max-width:480px) {

    .a{
      
      margin-bottom: 750px;
    }
    
    .a-card {
      height: 40vh;
    }

  }

  @media screen and (max-width:380px) {

    .a{
      
      margin-bottom: 850px;
    }
    
    
  }